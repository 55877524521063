<template>
  <v-layout class="ma-0 pa-0">
    <v-menu
      :absolute="false"
      :open-on-hover="false"
      :close-on-click="true"
      :close-on-content-click="false"
      :offset-y="true"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          small
          tile
          :elevation="0"
          color="gray"
          v-on="on"
          v-bind="attrs"
          :loading="downloading"
        >
          <v-icon small color="neuter" left
          >mdi-cog
          </v-icon
          >
          <span class="neuter--text">{{ $t("general.buttons.options") }}</span>
          <template v-slot:loader>
            <v-progress-circular
              style="position: absolute;
                               margin-left: 10px;
                               height: 15px;"
              color="neuter"
              indeterminate
              rounded
            />
          </template>
        </v-btn>
      </template>
      <v-list class="d-flex justify-start">
        <v-list-item
          dense
          class="ma-0 pa-2"
          v-for="(item, index) in moreOptionsItems" :key="index"
        >
          <div class="d-block">
          <v-btn small text @click="selectNomenclatorActions(item.action, items)">
            <v-icon small color="neuter" left>{{ item.icon }}</v-icon>
            {{ item.title }}
          </v-btn>
          </div>
        </v-list-item>
      </v-list>
    </v-menu>
    <v-dialog v-model="showDialogImport" persistent width="600px">
      <v-card>
        <v-card-title class="headline secondary t-bw-secondary--text">{{ $t("general.titles.nomenclators.importDataTable") + " " +
        $t(`${stringTranslate}.fields.name`) + "(s)" }}
        </v-card-title>
        <v-card-text class="pa-6">
          <v-form ref="form" v-model="valid">
            <v-file-input
              outlined
              dense
              required class="required"
                          :rules="requireRulesSizeAndType(fileSelected)"
                          show-size
                          v-model="fileSelected" color="primary"
                          prepend-icon="mdi-paperclip"
                          :label="$t('documents.fields_add.file')"
                          small-chips
                          @change="changeFile()">
            </v-file-input>
          </v-form>
          <v-alert
            class="mt-2"
            border="left"
            color="orange"
            dense
            text
            type="warning"
            icon="mdi-alert-outline"
          >
            {{ $t("general.titles.warningUploadTemplate") }}
          </v-alert>

          <v-divider></v-divider>

          <v-list >
            <v-list-item class="px-0">
              <v-list-item-content>
                <v-list-item-title>{{ $t("general.titles.nomenclators.downloadTemplate") }}
                </v-list-item-title>
                <v-list-item-subtitle>{{ $t("general.titles.uploadTemplateNote") }}
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-btn :loading="loadingT" small elevation="0" color="primary" class="t-bw-primary--text" @click="downloadTemplate">
                  {{ $t("general.buttons.generate") }}
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-card-text>
        <v-card-actions class="pa-6">
          <v-spacer></v-spacer>
          <v-btn small text color="neuter" @click="closeDialogImport()">
            {{ $t("general.buttons.cancel") }}
          </v-btn>
          <v-btn :loading="loading" small elevation="0" color="primary" class="t-bw-primary--text" :disabled="!valid" @click="importTemplate">
            {{ $t("general.buttons.import") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
  import i18n from "@/plugins/i18n"
  import LanguajeService from "../services/LanguajeService"
  import { mapActions, mapGetters } from "vuex"
  import ProductService from "../services/ProductService"
  import InputService from "../services/InputService"
  import SeasonsService from "../services/SeasonsService"
  import EquipmentService from "../services/EquipmentService"
  import OperatorService from "../services/OperatorService"
  import LocationService from "../services/Locations"
  import StorageService from "@/services/StorageService"
  import _ from "lodash"

  const PROFILE = JSON.parse(localStorage.getItem("profile"))

  export default {
    name: "MoreOptions",
    props: {
      items: {
        type: Array,
        required: true,
      },
      stringTranslate: {
        type: String,
        required: true,
      },
      stringTemplate: {
        type: String,
        required: false,
      },
      filters: {
        type: Object,
        required: false,
        default: () => ({}),
      },
    },
    data: () => ({
      valid: false,
      fileEditChange: false,
      fileSelected: null,
      showDialogImport: false,
      moreOptionsItems: [
        { title: i18n.t("general.buttons.import"), icon: "mdi-upload-multiple", action: "IMPORT" },
        { title: i18n.t("general.buttons.export"), icon: "mdi-download-multiple", action: "EXPORT" },
      ],
      loading: false,
      loadingT: false,
      downloading: false,
    }),
    created() {
      this.profile = JSON.parse(localStorage.getItem("profile"))
    },
    computed: {
      ...mapGetters({
        requireRulesSizeAndType: "general/requireRulesSizeAndType",
      }),
      nomenclatorAlert: {
        get() {
          return this.$store.getters["ui/GET_NOMENCLATOR_DATA"]
        },
        set(val) {
          this.$store.commit("ui/NOMENCLATOR_DATA", val)
        },
      },
      notification: {
        get() {
          return this.$store.getters["ui/GET_NOTIFICATION"]
        },
        set(val) {
          this.$store.commit("ui/NOTIFICATION", val)
        },
      },
    },
    methods: {
      ...mapActions({
        fetchListInputs: "input/fetchListInputs",
        fetchListEquipment: "equipment/fetchListEquipment",
        fetchListOperator: "operator/fetchListOperator",
        fetchListSeasons: "season/fetchListSeasons",
        fetchListProduct: "product/fetchListProduct",
      }),
      alert(data) {
        let icon = data.importState === "ERRORED" || data.importState === "PARTIALLY" ? "mdi-alert-outline" : "mdi-check-all"
        let color = data.importState === "ERRORED" || data.importState === "PARTIALLY" ? "error" : "secondary"
        let message = data.importState === "ERRORED" || data.importState === "PARTIALLY" ? i18n.t("general.notification.importError") : i18n.t("general.notification.importSuccess")

        this.nomenclatorAlert.icon = icon
        this.nomenclatorAlert.color = color
        this.nomenclatorAlert.message = message
        this.nomenclatorAlert.import = data.itemsImported
        this.nomenclatorAlert.error = data.rowsFailed.length
        this.nomenclatorAlert.total = data.totalItems
        this.nomenclatorAlert.rowsFailed = data.rowsFailed

      },
      selectNomenclatorActions(type, items) {
        switch (type) {
          case "IMPORT":
            this.showDialogImport = true
            break
          case "EXPORT":
            this.exportSelectedItemsOrAll(items)
            break
        }
      },
      closeDialogImport() {
        this.showDialogImport = !this.showDialogImport
        this.fileSelected = null
        this.$refs.form.reset()
      },
      saveExcel(name, response, action) {
        const FileSaver = require("file-saver")
        const blob = new Blob([response.data], {
          type: "application/xlsx",
        })
        if (action === "export") {
          switch (name) {
            case "storage":
              name = this.$t("storage.titles.exportTitle") + ".xlsx"
              break
            case "location":
              name = this.$t("locations.titles.exportTitle") + ".xlsx"
              break
            default:
              name = name + "_data.xlsx"
              break
          }

          FileSaver.saveAs(blob, name)
        } else {
          switch (name) {
            case "storage":
              name = this.$t("storage.titles.importTitle") + ".xlsx"
              break
            case "location":
              name = this.$t("locations.titles.importTitle") + ".xlsx"
              break
            default:
              name = name + "_template.xlsx"
              break
          }
          FileSaver.saveAs(blob, name)
        }
      },
      async downloadTemplate() {
        this.loadingT = true
        let language = LanguajeService.getLenguajeName()
        switch (this.stringTemplate) {
          case "location":
            await LocationService.fetchTemplate({
              language: language,
            })
              .then((response) => {
                this.saveExcel("location", response)
                this.$toast.success(i18n.t("general.notification.downloadTemplateSuccess"), {
                  icon: 'mdi-check-circle',
                  queueable: true,
                })
              })
              .catch((error) => {
                this.$toast.error(i18n.t("general.notification.downloadTemplateError"), {
                  queueable: true
                })
              })
              .finally(() => {
                this.loadingT = false
              })
            break
          case "storage":
            await StorageService.fetchTemplate({
              language: language,
            })
              .then((response) => {
                this.saveExcel("storage", response)
                this.$toast.success(i18n.t("general.notification.downloadTemplateSuccess"), {
                  queueable: true,
                })
              })
              .catch((error) => {
                this.$toast.error(i18n.t("general.notification.downloadTemplateError"), {
                  queueable: true
                })
              })
              .finally(() => {
                this.loadingT = false
              })
            break
          case "product":
            await ProductService.downloadTemplate(language)
              .then((response) => {
                this.saveExcel("product", response)
                this.$toast.success(i18n.t("general.notification.downloadTemplateSuccess"), {
                  queueable: true,
                })
              })
              .catch((error) => {
                this.$toast.error(i18n.t("general.notification.downloadTemplateError"), {
                  queueable: true
                })
              })
              .finally(() => {
                this.loadingT = false
              })
            break
          case "season":
            await SeasonsService.downloadTemplate(language)
              .then((response) => {
                this.saveExcel("season", response)
                this.$toast.success(i18n.t("general.notification.downloadTemplateSuccess"), {
                  queueable: true,
                })
              })
              .catch((error) => {
                this.$toast.error(i18n.t("general.notification.downloadTemplateError"), {
                  queueable: true
                })
              })
              .finally(() => {
                this.loadingT = false
              })
            break
          case "equipment":
            await EquipmentService.downloadTemplate(language)
              .then((response) => {
                this.saveExcel("equipment", response)
                this.$toast.success(i18n.t("general.notification.downloadTemplateSuccess"), {
                  queueable: true,
                })
              })
              .catch((error) => {
                this.$toast.error(i18n.t("general.notification.downloadTemplateError"), {
                  queueable: true
                })
              })
              .finally(() => {
                this.loadingT = false
              })
            break
          case "input":
            await InputService.downloadTemplate(language)
              .then((response) => {
                this.saveExcel("input", response)
                this.$toast.success(i18n.t("general.notification.downloadTemplateSuccess"), {
                  queueable: true,
                })
              })
              .catch((error) => {
                this.$toast.error(i18n.t("general.notification.downloadTemplateError"), {
                  queueable: true
                })
              })
              .finally(() => {
                this.loadingT = false
              })
            break
          case "operator":
            await OperatorService.downloadTemplate(language)
              .then((response) => {
                this.saveExcel("operator", response)
                this.$toast.success(i18n.t("general.notification.downloadTemplateSuccess"), {
                  queueable: true,
                })
              })
              .catch((error) => {
                this.$toast.error(i18n.t("general.notification.downloadTemplateError"), {
                  queueable: true
                })
              })
              .finally(() => {
                this.loadingT = false
              })
            break
        }
      },
      async importTemplate() {
        this.loading = true
        switch (this.stringTemplate) {
          case "location":
            await LocationService.import({
              language: LanguajeService.getLenguajeName(),
              file: this.fileSelected,
            })
              .then((response) => {
                const data = response.data
                this.alert(data)
                this.notification = true
              })
              .catch((error) => {
                this.$toast.error(i18n.t("general.notification.importError"), {
                  queueable: true
                })
              })
              .finally(() => {
                this.closeDialogImport()
                //this.$root.$emit('setFiltersLocations')
                this.loading = false
              })
            break
          case "storage":
            await StorageService.import({
              language: LanguajeService.getLenguajeName(),
              file: this.fileSelected,
            })
              .then((response) => {
                const data = response.data
                this.alert(data)
                this.notification = true
              })
              .catch((error) => {
                this.$toast.error(i18n.t("general.notification.importError"), {
                  queueable: true
                })
              })
              .finally(() => {
                this.closeDialogImport()
                //this.$root.$emit('setFiltersLocations')
                this.loading = false
              })
            break
          case "product":
            await ProductService.import(this.profile.company_id, this.fileSelected)
              .then((response) => {
                const data = response.data
                this.alert(data)
                this.notification = true
              })
              .catch((error) => {
                this.$toast.error(i18n.t("general.notification.importError"), {
                  queueable: true
                })
              })
              .finally(() => {
                this.closeDialogImport()
                this.fetchListProduct([{
                  companyId: PROFILE.company_id,
                  search: null,
                  pageableDTO: {
                    page: 0,
                    size: 10,
                    sortBy: "id",
                    direction: "DESC",
                  },
                }, this.$toast])
                this.loading = false
              })
            break
          case "season":
            await SeasonsService.import(this.profile.company_id, this.fileSelected)
              .then((response) => {
                const data = response.data
                this.alert(data)
                this.notification = true
              })
              .catch((error) => {
                this.$toast.error(i18n.t("general.notification.importError"), {
                  queueable: true
                })
              })
              .finally(() => {
                this.closeDialogImport()
                this.fetchListSeasons([this.profile, this.$toast])
                this.loading = false
              })
            break
          case "equipment":
            await EquipmentService.import(this.profile.company_id, this.fileSelected)
              .then((response) => {
                const data = response.data
                this.alert(data)
                this.notification = true
              })
              .catch((error) => {
                this.$toast.error(i18n.t("general.notification.importError"), {
                  queueable: true
                })
              })
              .finally(() => {
                this.closeDialogImport()
                this.fetchListEquipment([this.profile, this.$toast])
                this.loading = false
              })
            break
          case "input":
            await InputService.import(this.profile.company_id, this.fileSelected)
              .then((response) => {
                const data = response.data
                this.alert(data)
                this.notification = true
              })
              .catch((error) => {
                this.$toast.error(i18n.t("general.notification.importError"), {
                  queueable: true
                })
              })
              .finally(() => {
                this.closeDialogImport()
                this.fetchListInputs([this.profile, this.$toast])
                this.loading = false
              })
            break
          case "operator":
            await OperatorService.import(this.profile.company_id, this.fileSelected)
              .then((response) => {
                const data = response.data
                this.alert(data)
                this.notification = true
              })
              .catch((error) => {
                this.$toast.error(i18n.t("general.notification.importError"), {
                  queueable: true
                })
              })
              .finally(() => {
                this.closeDialogImport()
                this.fetchListOperator([this.profile, this.$toast])
                this.loading = false
              })
            break
        }
      },
      exportSelectedItemsOrAll() {
        this.downloading = true
        let language = LanguajeService.getLenguajeName()
        switch (this.stringTemplate) {
          case "location":
            LocationService.exportCSV({
              language: language,
              versionIds: this.getByProperty(this.items, "id"),
              ...this.filters,
            })
              .then((response) => {
                this.saveExcel("location", response, "export", "export")
                this.$toast.success(i18n.t("general.notification.exportSuccess"), {
                  queueable: true,
                })
              })
              .catch((error) => {
                this.$toast.error(i18n.t("general.notification.exportError"), {
                  queueable: true
                })
                this.showDialogImport = false
              })
              .finally(() => {
                this.showDialogImport = false
                this.downloading = false
              })
            break
          case "storage":
            StorageService.exportCSV({
              language: language,
              versionIds: this.getByProperty(this.items, "id"),
              ...this.filters,
            })
              .then((response) => {
                this.saveExcel("storage", response, "export")
                this.$toast.success(i18n.t("general.notification.exportSuccess"), {
                  queueable: true
                })
              })
              .catch((error) => {
                this.$toast.error(i18n.t("general.notification.exportError"), {
                  queueable: true
                })
                this.showDialogImport = false
              })
              .finally(() => {
                this.showDialogImport = false
                this.downloading = false
              })
            break
          case "product":
            ProductService.export(language)
              .then((response) => {
                this.saveExcel("product", response, "export", "export")
                this.$toast.success(i18n.t("general.notification.exportSuccess"), {
                  queueable: true,
                })
              })
              .catch((error) => {
                this.$toast.error(i18n.t("general.notification.exportError"), {
                  queueable: true
                })
                this.showDialogImport = false
              })
              .finally(() => {
                this.showDialogImport = false
                this.downloading = false
              })
            break
          case "season":
            SeasonsService.export(language)
              .then((response) => {
                this.saveExcel("season", response, "export")
                this.$toast.success(i18n.t("general.notification.exportSuccess"), {
                  queueable: true,
                })
              })
              .catch((error) => {
                this.$toast.error(i18n.t("general.notification.exportError"), {
                  queueable: true
                })
                this.showDialogImport = false
              })
              .finally(() => {
                this.showDialogImport = false
                this.downloading = false
              })
            break
          case "equipment":
            EquipmentService.export(language)
              .then((response) => {
                this.saveExcel("equipment", response, "export")
                this.$toast.success(i18n.t("general.notification.exportSuccess"), {
                  queueable: true,
                })
              })
              .catch((error) => {
                this.$toast.error(i18n.t("general.notification.exportError"), {
                  queueable: true
                })
                this.showDialogImport = false
              })
              .finally(() => {
                this.showDialogImport = false
                this.downloading = false
              })
            break
          case "input":
            InputService.export(language)
              .then((response) => {
                this.saveExcel("input", response, "export")
                this.$toast.success(i18n.t("general.notification.exportSuccess"), {
                  queueable: true,
                })
              })
              .catch((error) => {
                this.$toast.error(i18n.t("general.notification.exportError"), {
                  queueable: true
                })
                this.showDialogImport = false
              })
              .finally(() => {
                this.showDialogImport = false
                this.downloading = false
              })
            break
          case "operator":
            OperatorService.export(language)
              .then((response) => {
                this.saveExcel("operator", response, "export")
                this.$toast.success(i18n.t("general.notification.exportSuccess"), {
                  queueable: true,
                })
              })
              .catch((error) => {
                this.$toast.error(i18n.t("general.notification.exportError"), {
                  queueable: true
                })
                this.showDialogImport = false
              })
              .finally(() => {
                this.showDialogImport = false
                this.downloading = false
              })
            break
        }
      },
      changeFile() {
        if (this.editedIndex > 0) {
          this.fileEditChange = true
        }
      },

      getByProperty(array, property) {
        return _.map(array, property)
      },
    },
  }
</script>

<style scoped>
  .layout {
    display: contents !important;
  }

  .v-menu__content {
    border-radius: .5em;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px !important;
    margin-top: 10px;
  }
</style>
